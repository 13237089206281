import _ from 'lodash';
export default {
  props:{},   
  data() {
    return {
    }
  },  
  methods:{
    calculateCallDuration(duration){
        let minutes = Math.floor(duration/60)
        let seconds = duration%60

        if(minutes < 10){
            minutes = '0'+minutes; 
        }
        if(seconds < 10){
            seconds = '0'+seconds; 
        }
        let time = minutes + ' : ' + seconds;
        return time;

    },
    checkContact(call){
        let caller_name = '';
        if(caller_name = this.phoneContacts.find(item => item.phone_number.includes(call.cnam))){
            caller_name = caller_name.name;
        }else{
            if(call.cnam === call.cnum){
                caller_name = ''

            }else{
                caller_name = call.cnam
            }
        }
        return caller_name;
    },
    listenToTheAudio(call){
        let url = '';
        let month = new Date(call.calldate).getMonth() + 1;
        if(month < 10){
            month = '0' + month;
        }
        
        let day = new Date(call.calldate).getDate();
        if(day < 10){
            day = '0' + day;
        }

        url += new Date(call.calldate).getFullYear() + '/' + month + '/' + day;
        url += '/'+call.recordingfile;
        url = process.env.VUE_APP_CALL_HISTORY_URL + '/' + url;
        return url
    }
  }

}